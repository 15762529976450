import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { IPossibleStay } from '@common-types';
import { IAccount } from '@share/common-types';
import { getAccountLocale } from '@i18n';

import './style.scss';

interface IProps {
  account: IAccount | null | undefined;
  possibleStays: IPossibleStay[];
  displayMoreOptions: boolean;
  displayFullDateFormat?: boolean;
  displayHeader?: boolean;
}

export class VacantionRentalPossiblestaysDisclaimer extends React.Component<IProps> {
  public static defaultProps = {
    displayFullDateFormat: false,
    displayHeader: true
  };

  render(): React.ReactNode {
    const { possibleStays, displayMoreOptions, displayFullDateFormat, displayHeader, account } = this.props;

    const vacationRentalDays = get(possibleStays, '[0].days', 0);
    const vacationRentalFirstDate: any = get(possibleStays, '[0]', null);
    const vacationRentalMoreOptions = possibleStays?.length ? possibleStays.length - 1 : 0;

    const checkIn = moment(vacationRentalFirstDate.checkIn, 'yyyy-MM-DD');
    const checkOut = moment(vacationRentalFirstDate.checkOut, 'yyyy-MM-DD');
    const checkInFormat = !displayFullDateFormat ? 'DD-MMM' : checkIn.year() === checkOut.year() ? 'dddd, DD MMMM' : 'dddd, DD MMMM, yyyy';
    const checkOutFormat = !displayFullDateFormat ? 'DD-MMM' : 'dddd, DD MMMM, yyyy';
    const locale = getAccountLocale(account);

    return (
      <div className="vacantion-rental-possiblestays-disclaimer">
        <div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer">
          {displayHeader ? (<div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer-header"><FormattedMessage id="vacation.rental.reservation.disclaimer" /></div>) : null}
          <div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer-content">
            {vacationRentalDays > 0 && (<div><FormattedMessage id="vacation.rental.property.only.allows" /> {vacationRentalDays}<FormattedMessage id="vacation.rental.day.reservation" /></div>)}
            <div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer-content-days">
              {vacationRentalFirstDate && (
                <div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer-content-day">
                  {moment(vacationRentalFirstDate.checkIn, 'yyyy-MM-DD').locale(locale).format(checkInFormat)} - {moment(vacationRentalFirstDate.checkOut, 'yyyy-MM-DD').locale(locale).format(checkOutFormat)}
                </div>
              )}
              {(displayMoreOptions && vacationRentalMoreOptions > 0) ? (
                <div className="vacantion-rental-possiblestays-disclaimer__condo-disclaimer-content-day">+{vacationRentalMoreOptions} <FormattedMessage id="vacation.rental.more.options" /></div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
