

import { get } from 'lodash';

import Spa from '@assets/images/amenities/spa.png';
import Air from '@assets/images/amenities/air.png';
import Restaurant from '@assets/images/amenities/restaurant.png';
import Beakfast from '@assets/images/amenities/breakfast.png';
import Parking from '@assets/images/amenities/parking.png';
import RoomService from '@assets/images/amenities/room-service.png';
import FrontDesk from '@assets/images/amenities/front-desk.png';
import Pool from '@assets/images/amenities/pool.png';
import Wifi from '@assets/images/amenities/wifi.png';
import FreeWifi from '@assets/images/amenities/free-wifi.png';
import Gym from '@assets/images/amenities/gym.png';
import BusinessService from '@assets/images/amenities/business-service.png';
import Bar from '@assets/images/amenities/bar.png';
import Housekeeping from '@assets/images/amenities/housekeeping.png';
import PetFriendly from '@assets/images/amenities/pet-friendly.png';
import Laundry from '@assets/images/amenities/laundry.png';
import NonSmokingRooms from '@assets/images/amenities/non-smoking-rooms.png';
import FacilitiesDisabledGuests from '@assets/images/amenities/facilities-disabled-guests.png';
import Beakfast2 from '@assets/images/amenities/breakfast-2.png';
import SpaCredit from '@assets/images/amenities/spa-credit.png';
import CasinoCredit from '@assets/images/amenities/casino-credit.png';
import FreeMassage from '@assets/images/amenities/free-massage.png';
import FreeMinibar from '@assets/images/amenities/free-minibar.png';
import BuffetDinner from '@assets/images/amenities/buffet-dinner.png';
import IroningBoard from '@assets/images/amenities/ironing-board.png';
import Kitchenette from '@assets/images/amenities/kitchenette.png';
import HairDryer from '@assets/images/amenities/hair-dryer.png';
import HeatedGuestRooms from '@assets/images/amenities/heated-guest-rooms.png';
import DVDRental from '@assets/images/amenities/dvd-rental.png';
import ATM from '@assets/images/amenities/atm.png';
import FrontDesk24 from '@assets/images/amenities/front-desk-24.png';
import FreeShuttle from '@assets/images/amenities/free-shuttle.png';
import FreeParking from '@assets/images/amenities/free-parking.png';
import IceMachine from '@assets/images/amenities/ice-machine.png';
import Jacuzzi from '@assets/images/amenities/jacuzzi.png';
import Playground from '@assets/images/amenities/playground.png';
import SafeDepositBox from '@assets/images/amenities/safe-deposit-box.png';
import PetAmenities from '@assets/images/amenities/pet-amenities.png';
import PetsAllowed from '@assets/images/amenities/pets-allowed.png';
import TennisCourt from '@assets/images/amenities/tennis-court.png';
import { IAmenities } from '@common-types';

const AMENITIES_MAPPING: any[] = [
  { id: 1, name: 'Spa', image: Spa },
  { id: 2, name: 'Air conidtioning', image: Air },
  { id: 3, name: 'Restaurant', image: Restaurant },
  { id: 4, name: 'Breakfast available', image: Beakfast },
  { id: 5, name: 'Parking available', image: Parking },
  { id: 6, name: 'Room service', image: RoomService },
  { id: 7, name: '24/7 front desk', image: FrontDesk },
  { id: 8, name: 'Pool', image: Pool },
  { id: 9, name: 'Wifi', image: Wifi },
  { id: 10, name: 'Free Wifi', image: FreeWifi },
  { id: 11, name: 'Gym', image: Gym },
  { id: 12, name: 'Business services', image: BusinessService },
  { id: 13, name: 'Bar', image: Bar },
  { id: 14, name: 'Housekeeping', image: Housekeeping },
  { id: 15, name: 'Pet friendly', image: PetFriendly },
  { id: 16, name: 'Laundry', image: Laundry },
  { id: 17, name: 'Non-smoking rooms', image: NonSmokingRooms },
  { id: 18, name: 'Facilities for disabled guests', image: FacilitiesDisabledGuests },
  { id: -1, name: 'Breakfast for 2', image: Beakfast2 },
  { id: -1, name: 'Spa Credit', image: SpaCredit },
  { id: -1, name: 'Casino credit', image: CasinoCredit },
  { id: -1, name: 'Free massage included', image: FreeMassage },
  { id: -1, name: 'Free minibar', image: FreeMinibar },
  { id: -1, name: 'Buffet dinner', image: BuffetDinner },
  { id: 256, name: 'Ironing Board', image: IroningBoard },
  { id: 277, name: 'Kitchenette', image: Kitchenette },
  { id: 255, name: 'Hair Dryer', image: HairDryer },
  { id: 257, name: 'Heated Guest Rooms', image: HeatedGuestRooms },
  { id: 199, name: 'DVD Video Rental', image: DVDRental },
  { id: 27, name: 'ATM Cash Machine', image: ATM },
  { id: 22, name: 'Front Desk 24 Hour', image: FrontDesk24 },
  { id: 61, name: 'Free Airport Shuttle', image: FreeShuttle },
  { id: 62, name: 'Free Parking', image: FreeParking },
  { id: 72, name: 'Ice Machine', image: IceMachine },
  { id: 75, name: 'Jacuzzi', image: Jacuzzi },
  { id: 90, name: 'Playground', image: Playground },
  { id: 95, name: 'Safe Deposit Box', image: SafeDepositBox },
  { id: 164, name: 'Front Desk', image: FrontDesk },
  { id: 230, name: 'Pet Amenities Available', image: PetAmenities },
  { id: 240, name: 'Pets Allowed', image: PetsAllowed },
  { id: 248, name: 'Tennis Court', image: TennisCourt },

  { id: 86, name: 'Outdoor Pool', image: Pool },
  { id: 360, name: 'Fitness Center', image: Gym },
  { id: 42, name: 'Concierge Desk', image: FrontDesk },
  { id: 22, name: 'Front Desk 24 Hour', image: FrontDesk },
  { id: 78, name: 'Laundry Valet Service', image: Laundry },
  { id: 244, name: 'Business Center', image: BusinessService },
  { id: 70, name: 'Housekeeping Daily', image: Housekeeping },
  { id: 327, name: 'Smoke Free Property', image: NonSmokingRooms },
  { id: 301, name: 'Complimentary Wireless Internet', image: Wifi },
  { id: 181, name: 'Lounges Bars', image: Bar },
];


export const getAmenityImage = (amenity: IAmenities) => {
  const imageByName = getAmenityImageByName(amenity.name);
  const imageById = get(AMENITIES_MAPPING.filter(a => a.id === amenity.amenityId), '[0]', null);
  const response = imageById || imageByName;
  return response || {} as any;
}

export const getAmenityImageByName = (amenityName: string) => {
  return get(AMENITIES_MAPPING.filter(a => a.name === amenityName), '[0]', null);
}