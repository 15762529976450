import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ILoginState } from '@share/store/slices';
import { GetHomeParams, RootState, VACATIONS_PAGE } from '@share/utils';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import SandBeach from '@assets/images/sand-beach.png';

import './style.scss';

export enum GoToType {
  CONDOS,
  VACATION_RENTALS,
  WEEKS,
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  type: GoToType | null | undefined;
}

function GoToComponent(props: IProps) {
  const { type, loginStore, history } = props;
  const { account } = loginStore;

  const isCondo = useMemo(() => type === GoToType.CONDOS, [type]);
  const isVacationRentals = useMemo(() => type === GoToType.VACATION_RENTALS, [type]);
  const isWeeks = useMemo(() => type === GoToType.WEEKS, [type]);

  const homeParams = useMemo(() => GetHomeParams(account), [account]);

  const searchMessage = useMemo(() => isCondo ? 'search.hotel' : 'search.condos', [isCondo]);
  const searchTitle = useMemo(() => isCondo ? 'no.result.search.over' : 'no.result.search.over.condos', [isCondo]);

  const hangleGoto = () => {
      if (account?.isDisableHomes) {
      if (account?.disableHomesRedirect && !isEmpty(account?.disableHomesRedirect)) {
        window.location.replace(account?.disableHomesRedirect);
      } else {
        history.push(Routes.NotAuthorized);
      }
    } else {
      const route = isCondo ? Routes.CondoSearch : isWeeks ? Routes.WeeksSearch : isVacationRentals ? `${Routes.Search}/${VACATIONS_PAGE}` : Routes.Search;
      history.push(`/${account?.name}${route}${homeParams}`);
    }
  }

  return (
    <div className="go-to">
      <img src={SandBeach} alt="" />
      <div className="message container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div className="text">
              <label><FormattedMessage id="no.result.not.finding" /></label>
              <span><FormattedMessage id={searchTitle} /></span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="button" onClick={hangleGoto}>
              <BlueButton>
                <FormattedMessage id={searchMessage} />
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const GoTo = connect(mapStateToProps)(withRouter(GoToComponent));
