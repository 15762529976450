import React from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { get } from 'lodash';
import { connect } from 'react-redux';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IBounds, IClientCash, IGeoLocation, IHotel } from '@share/common-types';
import { Map, WalletPriceType, getWalletPrices, openHotelPage } from '@utils';
import { ExpiredSessionModal } from '@components';
import { GetDisableNewTabOpen, getSelectedCurrency, Responsive, RootState } from '@share/utils';
import { IHotelsState, ILoginState, IMarginatorState, IMenuState, hotelsActions } from '@share/store/slices';

import { HotelCardComponent } from '../../result/hotel-card';

import MapMarker from '@assets/images/map-price-icon.png';
import MapMarkerSelected from '@assets/images/map-price-selected-icon.png';

import './style.scss';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';

interface IMapStateToProps {
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
  marginatorStore: IMarginatorState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  bounds?: IBounds;
  center?: google.maps.LatLngLiteral;
  locations: {
    location: IGeoLocation;
    id: number;
  }[];
  icon?: string;
  mapOptions?: google.maps.MapOptions;
  hotels?: IHotel[];
  nights?: number;
  withEvents?: boolean;
  selectedHotel?: IHotel;
  setMapDistance?: (distance: number) => void;
  onSearchUpdate?: () => void;
  setBounds?: (bounds: IBounds) => void;
  disabled?: boolean;
  hideExpiredMessage?: boolean;
  isVacationRentals?: boolean | null | undefined;
}

interface IState {
  selectedHotel: IHotel | null | undefined;
}

const zero = 0;
const debounceTime = 1000;

class MapWrapperComponent extends React.Component<IProps, IState> {
  mapRef: React.RefObject<HTMLDivElement> = React.createRef();
  map!: google.maps.Map;
  state: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedHotel: props.selectedHotel ? props.selectedHotel : null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.hotels !== this.props.hotels) {
      if (this.props.hotels?.some(({ hotelId }) => hotelId === this.props.selectedHotel?.hotelId)) {
        this.setState({ selectedHotel: this.props.selectedHotel });
      } else {
        this.setState({ selectedHotel: null });
      }

      Map.removeAllMarkers();
      this.setMarkers();
    }

    if (prevProps.bounds && this.props.bounds && !isEqual(prevProps.bounds, this.props.bounds)) {
      this.fitMapToBounds();
    }
  }

  selectHotel = (hotelId: number): void => {
    const { hotels = [] } = this.props;
    const selectedHotel = hotels.find((hotel: IHotel) => hotel.hotelId === hotelId);

    if (selectedHotel) {
      this.setState({ selectedHotel });

      const markers = document.querySelectorAll('.price-marker');

      if (markers && markers.length) {
        markers.forEach((marker) => {
          (marker as HTMLDivElement).style.zIndex = '1';
          (marker as HTMLDivElement).style.background = `url(${MapMarker})`;
        });
      }

      const selectedMarker = document.querySelector(
        `.price-marker[data-item-id="${hotelId}"]`,
      ) as HTMLDivElement;

      if (selectedMarker) {
        selectedMarker.style.zIndex = '5';
        selectedMarker.style.background = `url(${MapMarkerSelected})`;
      }
    }
  };

  setMarkers = (): void => {
    const { locations, icon, selectedHotel, loginStore } = this.props;
    const { account } = loginStore;
    const currency = getSelectedCurrency(account);

    Map.addMarkers(this.map, locations, icon ? icon : '', currency, selectedHotel?.hotelId, false, this.selectHotel);
    Map.addCluster(this.map);
  };

  fitMapToBounds = () => {
    const bounds = this.map.getBounds();
    let mapBounds = null;

    if (bounds) {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      mapBounds = {
        northEast: {
          longitude: ne.lng(),
          latitude: ne.lat(),
        },
        southWest: {
          longitude: sw.lng(),
          latitude: sw.lat(),
        },
      };
    }

    if (!isEqual(this.props?.bounds, mapBounds)) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
        Map.getGoogleLocation(this.props.bounds?.southWest),
        Map.getGoogleLocation(this.props.bounds?.northEast),
      );

      this.map.fitBounds(bounds, zero);
    }
  };

  componentDidMount(): void {
    const { center, bounds = null, withEvents = false, mapOptions = {} } = this.props;

    if (this.mapRef && this.mapRef.current) {
      this.map = Map.initializeMap(this.mapRef.current, bounds ? undefined : center, mapOptions);

      if (bounds) {
        this.fitMapToBounds();
      }

      this.setMarkers();

      this.map.addListener('click', () => {
        if (this.state.selectedHotel) {
          this.setState({ selectedHotel: null });
        }
      });

      if (withEvents) {
        setTimeout(() => {
          this.map.addListener('center_changed', this.onCenterChange);
          this.map.addListener('zoom_changed', this.onCenterChange);
        }, debounceTime);
      }
    }
  }

  onCenterChange = debounce(() => {
    const bounds = this.map.getBounds();
    const sw = bounds?.getSouthWest();
    const ne = bounds?.getNorthEast();
    const resBounds = {
      northEast: {
        longitude: ne?.lng(),
        latitude: ne?.lat(),
      },
      southWest: {
        longitude: sw?.lng(),
        latitude: sw?.lat(),
      },
    };

    if (!isEqual(this.props?.bounds, resBounds)) {
      if (this.props.setBounds) {
        this.props.setBounds(resBounds);
      }

      if (this.props.onSearchUpdate) {
        this.props.onSearchUpdate();
      }
    }
  }, debounceTime);

  onHotelPage = (hotelId: number, selectedPropertyClientCash: number) => {
    const { isVacationRentals, loginStore, hotelsStore } = this.props;
    const { account } = loginStore;

    const { selectedHotelSearchClientCash } = hotelsStore;

    const clientCash: any = { ...selectedHotelSearchClientCash, selectedPropertyClientCash, selectedPropertyReviewClientCash: null };
    this.props.setSelectedHotelSearchClientCash(clientCash);

    const disableNewTabOpen = GetDisableNewTabOpen(this.props.loginStore.account, this.props.menuStore.items);

    openHotelPage(hotelId, this.props.history, disableNewTabOpen as boolean, account?.isTravelAgentPro, isVacationRentals, account?.forceIframe);
  };

  render(): React.ReactNode {
    const { selectedHotel } = this.state;
    const { isVacationRentals, loginStore, hotelsStore, marginatorStore, nights = zero, disabled = false, hideExpiredMessage = false } = this.props;
    const { account, userWalletData } = loginStore;
    const { selectedHotelSearchClientCash } = hotelsStore;
    const { value } = marginatorStore;

    // TODO fix this
    const marginatorPrice = (selectedHotel?.price ? selectedHotel?.price : 0) / (1 - (value / 100));

    const hotelPrices = getWalletPrices(account, userWalletData, selectedHotelSearchClientCash, marginatorPrice, selectedHotel?.maxWalletClientCash, WalletPriceType.Search);
    const clientCashApplied = hotelPrices?.clientCashApplied;

    return (
      <div className="map-wrapper">
        {disabled && (
          <>
            {!hideExpiredMessage && (
              <div className="map-wrapper__expired-message">
                <ExpiredSessionModal visible={!hideExpiredMessage} />
              </div>
            )}
            <div className="map-wrapper__disabled-overlay" />
          </>
        )}
        <div className="map-wrapper__map">
          <div className="map-wrapper__map-instance" ref={this.mapRef} />
        </div>
        {selectedHotel && (
          <div
            className="map-wrapper__hotel-detail"
            onClick={() => this.onHotelPage(selectedHotel.hotelId, clientCashApplied)}
          >
            <Responsive>
              <HotelCardComponent
                hotel={selectedHotel}
                isFromSearch={false}
                nights={!selectedHotel?.possibleStays?.length ? nights : get(selectedHotel, 'possibleStays[0].days', 0)}
                isVacationRentals={isVacationRentals}
              />
            </Responsive>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    loginStore: state.loginStore,
    marginatorStore: state.marginatorStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(hotelsActions.setSelectedHotelSearchClientCash(selectedClientCash));
  }
});


const MapWrapperRouter = withRouter(MapWrapperComponent);

export const MapWrapper = connect(mapStateToProps, mapDispatchToProps)(MapWrapperRouter);
