
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { HomesTypes } from '@share/constants';
import { GoTo, GoToType } from '@components';
import { IAccount } from '@share/common-types';

import NoHotelResults from '@share/assets/images/no_hotel_results.png';
import NoVacationResults from '@assets/images/no_vacation_results.png';
import NoCarsResults from '@share/assets/images/empty_cars_results.png';

import './style.scss';

interface IProps {
  filterHomes?: string;
  
  isVacationRentals?: boolean | null | undefined;
  isCars?: boolean;

  account?: IAccount;

  onReset: () => void;
}

export class EmptyFilterMessage extends React.Component<IProps> {
  render(): React.ReactNode {
    const { onReset, filterHomes, isVacationRentals, isCars, account } = this.props;
    let label = 'no.showing.filter';
    const msg = "change.search.criteria";
    let image = NoHotelResults;

    const isCapitalVacations = account?.isCapitalVacations;

    if (filterHomes === HomesTypes.VacationRentalOnly) {
      label = 'no.vacation.filter';
      image = NoVacationResults;
    } else if (filterHomes === HomesTypes.HotelOnly) {
      label = 'no.hotel.filter';
      image = NoHotelResults;
    } else if (isCars) {
      image = NoCarsResults;
    }

    return (
      <div className="no-filter-msg">
        <img src={image} alt="" />
        <div className="no-filter-msg__info text-center">
          <h3><strong>Oops!</strong></h3>
          <p className="no-filter-msg__title"><FormattedMessage id={label} /></p>
          <p className="no-filter-msg__text"><FormattedMessage id={msg} /></p>
          <p>
            <a onClick={onReset}>
              <FormattedMessage id="reset.all" />
            </a>
            {' '}
            <FormattedMessage id="reset.filter.right" />
          </p>

          {(!isCapitalVacations && !isCars) ? (<GoTo type={isVacationRentals ? GoToType.VACATION_RENTALS : null} />) : null}
        </div>
      </div>
    );
  }
}
