
import { Locale } from 'antd/lib/locale-provider';
import { isEmpty } from 'lodash';
import { Locale as LocaleDateFns } from 'date-fns';
import { enUS as enUSDF, es, fr, pt, it } from 'date-fns/esm/locale'

import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import ptBR from 'antd/es/locale/pt_BR';
import itIT from 'antd/es/locale/it_IT';
import frFR from 'antd/es/locale/fr_FR';

import { SELECTED_LANGUAGE_COUNRTY_LABEL, SELECTED_LANGUAGE_LABEL } from '@share/constants';
import { DEFAULT_LANGUAGE_FIELD_KEY, IAccount } from '@share/common-types';

import { getPreferenceFromAccount } from '../share/utils/accounts';

import enMessages from './en.json';
import esMessages from './es.json';
import ptMessages from './pt.json';
import frMessages from './fr.json';
import itMessages from './it.json';

import Brazil from '@share/assets/images/flags/brazil-flag-icon.png';
import France from '@share/assets/images/flags/france-flag-icon.png';
import Spain from '@share/assets/images/flags/spain-flag-icon.png';
import US from '@share/assets/images/flags/us-flag-icon.png';
import IT from '@share/assets/images/flags/italy-flag-icon.png';

export enum LocalesCountry {
  EnglishUS = 'enUS',
  Spanish = 'es',
  FrenchFR = 'frFR',
  PortugueseBR = 'ptBR',
  Italy = 'itIT',
}

export enum Locales {
  English = 'en',
  Spanish = 'es',
  Portuguese = 'pt',
  Italian = 'it',
  French = 'fr',
}

const flagMap = {
  [LocalesCountry.EnglishUS]: US,
  [LocalesCountry.Spanish]: Spain,
  [LocalesCountry.FrenchFR]: France,
  [LocalesCountry.PortugueseBR]: Brazil,
  [LocalesCountry.Italy]: IT,
};

const pick = (obj: any, arr: any[]) => Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));

const processKeys = (messages: { [key: string]: string }): { [key: string]: string } => {
  const messageKeys = Object.keys(messages);
  const enMessageKeys = Object.keys(enMessages);
  const enMessageKeysFiltered = enMessageKeys.filter(k => !messageKeys.includes(k));
  const enMessageMissing = pick(enMessages, enMessageKeysFiltered) as { [key: string]: string };

  return {...messages, ...enMessageMissing};
}

const messagesMap = {
  [Locales.English.toString()]: processKeys(enMessages),
  [Locales.Spanish.toString()]: processKeys(esMessages),
  [Locales.French.toString()]: processKeys(frMessages),
  [Locales.Portuguese.toString()]: processKeys(ptMessages),
  [Locales.Italian.toString()]: processKeys(itMessages),
};

const antLocalesMap: { [key: string]: Locale } = {
  [Locales.English]: enUS,
  [Locales.Spanish]: esES,
  [Locales.French]: frFR,
  [Locales.Portuguese]: ptBR,
  [Locales.Italian]: itIT,
};

export const getMessages = (locale: Locales | null | undefined): { [key: string]: string } => {
  if (locale && messagesMap[locale]) {
    return messagesMap[locale];
  }
  return enMessages;
};

export const getAntLocale = (locale: Locales | null | undefined): Locale => {
  return (locale ? antLocalesMap[locale] : enUS) || enUS;
};

export const getFlag = (locale: Locales, forceLocale?: boolean): any => {
  const locateCountry = localStorage.getItem(SELECTED_LANGUAGE_COUNRTY_LABEL);
  const ccountry = getLocaleCountry(locateCountry);
  const countryLocaleStorage = ccountry ? flagMap[ccountry] : undefined;
  const countryLocaleDefault = flagMap[getLocaleCountryByLocale(locale)];

  if (forceLocale) {
    return countryLocaleDefault;
  }
  
  return countryLocaleStorage || countryLocaleDefault;
};

export const getLocaleCountry = (language: string | null | undefined): LocalesCountry => {
  if (!isEmpty(language)) {
    if (language === LocalesCountry.EnglishUS) {
      return LocalesCountry.EnglishUS;
    }
    if (language === LocalesCountry.Spanish) {
      return LocalesCountry.Spanish;
    }
    if (language === LocalesCountry.FrenchFR) {
      return LocalesCountry.FrenchFR;
    }
    if (language === LocalesCountry.PortugueseBR) {
      return LocalesCountry.PortugueseBR;
    }
  }

  return LocalesCountry.EnglishUS;
};


export const getLocaleCountryByLocale = (locale: Locales): LocalesCountry => {
  if (locale) {
    if (locale === Locales.English) {
      return LocalesCountry.EnglishUS;
    }
    if (locale === Locales.Spanish) {
      return LocalesCountry.Spanish;
    }
    if (locale === Locales.Portuguese) {
      return LocalesCountry.PortugueseBR;
    }
    if (locale === Locales.French) {
      return LocalesCountry.FrenchFR;
    }
    if (locale === Locales.Italian) {
      return LocalesCountry.Italy;
    }
  }

  return LocalesCountry.EnglishUS;
};

export const getLocale = (language: string): Locales => {
  const defautlLocale = getLocaleWithDefault(language, Locales.English);
  if (defautlLocale) {
    return defautlLocale;
  }

  return Locales.English;
};

export const getLocaleWithDefault = (language: string, defaultValue?: Locales): Locales => {
  if (!isEmpty(language)) {
    if (language === Locales.English) {
      return Locales.English;
    }
    if (language === Locales.Spanish) {
      return Locales.Spanish;
    }
    if (language === Locales.Portuguese) {
      return Locales.Portuguese;
    }
    if (language === Locales.French) {
      return Locales.French;
    }
    if (language === Locales.Italian) {
      return Locales.Italian;
    }
  }

  if (defaultValue) {
    return defaultValue;
  }

  return Locales.English;
};

export const getAccountLocale = (account: IAccount | null | undefined): Locales => {
  const languageStorage = localStorage.getItem(SELECTED_LANGUAGE_LABEL);
  const defaultLanguagePref = getPreferenceFromAccount(account, DEFAULT_LANGUAGE_FIELD_KEY, Locales.English);
  return (languageStorage && !isEmpty(languageStorage)) ? getLocale(languageStorage) : getLocale(defaultLanguagePref);
};

export const getAccountDateFnsLocale = (account: IAccount): LocaleDateFns => {
  const languageStorage = localStorage.getItem(SELECTED_LANGUAGE_LABEL);
  const defaultLanguagePref = getPreferenceFromAccount(account, DEFAULT_LANGUAGE_FIELD_KEY, Locales.English);
  const locale = (languageStorage && !isEmpty(languageStorage)) ? getLocale(languageStorage) : getLocale(defaultLanguagePref);

  if (locale === Locales.English) {
    return enUSDF;
  }
  if (locale === Locales.Spanish) {
    return es;
  }
  if (locale === Locales.French) {
    return fr;
  }
  if (locale === Locales.Portuguese) {
    return pt;
  }
  if (locale === Locales.Italian) {
    return it;
  }

  return enUSDF;
};
