
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import Cookies from 'js-cookie';

import { TOKEN_HEADER_IS_WIDGET, COOKIE_TOKEN_NAME, COOKIE1_TOKEN_NAME, UNAUTHORIZED_STATUS, Urls, SESSION_KEY_TOKEN_NAME, SESSION_KEY_TOKEN_NAME_PARAM } from '@share/constants';
import { RESERVATION_CANCEL_PAGE, RESERVATION_CANCEL_REQUEST_PAGE } from './routes';
import { loginActions } from '../store/slices/login';
import { Locales, getAccountLocale } from '@i18n';
import { DecodeToken } from './impersonator';
import { IAccount } from '../common-types/account';
import { UrlUtils, LoginType } from '@share/utils';

export const axiosInstance = axios.create();
export const axiosInstanceB2B = axios.create();
export const axiosInstanceExternal = axios.create();

const TWO = 2;

const URLS_TO_FORCE_ENGLISH: string[] = [
  Urls.CondosRecommendedLocations,
  Urls.CondosRecentSearches,
  Urls.CondosLocationsPopularDestinations,
  Urls.CondosLocations,
  Urls.CondosSearchStrict,
  Urls.CondosSearchStrictPagination,
  Urls.CondosSearchFlexible,
  Urls.CondosSearchFlexiblePagination,
  Urls.CondosSearchAnytime,
  Urls.CondosSearchAnytimePagination,
  Urls.CondoDetails,
  Urls.CondoBook,
  Urls.CheckinStatus,
  Urls.CondoUnits,
  Urls.CondoCountries,
  Urls.CondoBookingSummary,
];

export function setupAxios(axiosInstance: AxiosInstance, store: any, isBff: boolean) {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!(config.url)?.startsWith('http')) {
        const API_URL = isBff ? import.meta.env.VITE_API_BFF_URL : import.meta.env.VITE_API_B2B_URL;
        const { loginStore } = store.getState();
        const authorization_token = loginStore?.userWallet ? loginStore?.userWallet?.accessToken : loginStore?.user?.accessToken;
        if (authorization_token) {
          config.headers.Authorization = `Bearer ${authorization_token}`;
        }

        if (URLS_TO_FORCE_ENGLISH.includes(config.url as string)) {
          config.headers['Accept-Language'] = Locales.English;
        } else {
          const locale = getAccountLocale(loginStore?.account);
          if (!isEmpty(locale)) {
            config.headers['Accept-Language'] = locale;
          }
        }

        config.url = `${API_URL}${config.url}`;
      }
      return config;
    },
    (error) => {
      return handleRequestError(error, store);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      return handleRequestError(error, store);
    },
  );  
}

const handleRequestError = (error: any, store: any) => {
  const pathNameSplit = location.pathname.split('/')
  if (error?.response?.status === UNAUTHORIZED_STATUS &&
      pathNameSplit[TWO] !== RESERVATION_CANCEL_REQUEST_PAGE &&
      pathNameSplit[TWO] !== RESERVATION_CANCEL_PAGE) {
    store.dispatch(loginActions.setIsTokenExpired(true));
    error.code = 'SESSIONEXPIRED';
  }

  return Promise.reject(error);
}

export const getHeaders = (isWidget?: boolean): { headers: { [key: string]: string | number } } => {
  const headers: any = {};

  headers[TOKEN_HEADER_IS_WIDGET] = !!isWidget;

  return { headers };
};

export const getTokenData = () => {
  const token = Cookies.get(COOKIE_TOKEN_NAME);
  if (!isEmpty(token)) {
    const data: any = {};
    
    data[COOKIE_TOKEN_NAME.toLowerCase()] = token;
    const token1 = Cookies.get(COOKIE1_TOKEN_NAME);
    if (!isEmpty(token1)) {
      data[COOKIE1_TOKEN_NAME.toLowerCase()] = token1;
    }
    return data;
  }
  return null;
}

export const getToken = (account: IAccount | null | undefined) => {
  if (account?.type === LoginType.PrivateWithToken) {
    if (account?.privateTokenFromUrl) {
      return { [SESSION_KEY_TOKEN_NAME]: UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM) };
    } else {
      return getTokenData();    
    }
  }
  return null;
}

export const DecodeTokenData = () => {
  try {
    const tokenData = getTokenData();
    if (tokenData) {
      let token = tokenData[COOKIE_TOKEN_NAME.toLowerCase()];
      const token1 = tokenData[COOKIE1_TOKEN_NAME.toLowerCase()]; 
      if (token1) {
        token = token.concat(token1);
      }
      const tokenDataDecoded = DecodeToken(token);
      if (tokenDataDecoded) {
        return JSON.parse(tokenDataDecoded);
      }
    }
  } catch(e) {
    console.error(e);
  }
  return null;
}